<template>
  <div class="h5-container h5-record-referral-code-box">
    <sub-head-navs title="推荐码记录"></sub-head-navs>
    <sub-refresh-list
      :list="recordList"
      :loading="isLoading"
      :error="error"
      :finished="isFinished"
      :refreshing="refreshing"
      @changeRefreshing="val => refreshing = val"
      @changeLoading="val => isLoading = val"
      @changeError="val => error = val"
      @refresh="onRefresh()">
      <sub-item slot-scope="{item}" :item="item" />
    </sub-refresh-list>
    <popup-share-menus />
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import subHeadNavs from '@/components/h5/headNavs'
  import subItem from './submodule/item'
  import subRefreshList from '@/components/h5/refreshList'
  import popupShareMenus from '@/components/h5/shareMenus'

  export default {
    components: {
      subHeadNavs,
      subItem,
      subRefreshList,
      popupShareMenus
    },
    data() {
      return {
        isLoading: false,
        isLoading: false,
        isFinished: false,
        refreshing: false,
        error: false,
        recordList: [],
        searchParams: {
          current: 1,
          pageSize: 15
        }
      }
    },
    computed: {
      ...mapGetters(['uccnInfo'])
    },
    created() {
      this.queryDatas();
    },
    methods: {
      queryDatas(current = 1) {
        if(current === 1 || this.refreshing) {
          this.recordList = [];
          this.refreshing = false;
        }
        this.searchParams.current = current;
        this.error = false;
        this.$http.get('/api/school/ambassador/h5/recomend/code/list.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data) {
            if(this.refreshing) {
              this.refreshing = false;
            }
            if(current === 1) {
              this.recordList = data;
            } else {
              this.recordList.push(...data);
            }
            this.searchParams.current++;
            if(this.recordList.length >= data.totalRecords) {
              this.isFinished = true;
            }
          } else {
            this.isFinished = true;
          }
        }).catch(() => {
          this.error = true;
        }).finally(() => {
          this.isLoading = false;
        });
      },
      onRefresh() {
        this.isFinished = false;
        this.isLoading = true;
        this.queryDatas();
      }
    }
  }
</script>