<template>
  <div class="record-referral-code-item">
    <div class="item-record">
      <span class="name el-sl">{{ item.name }}</span>
      <span v-if="item.code" class="referral-code">{{ item.code }}</span>
    </div>
    <div class="item-record">
      <span class="record-log">{{ item.content }}</span>
      <span class="create-time">{{ item.createTime | DATE('YYYY-MM-DD HH:mm') }}</span>
    </div>
  </div>
</template>
<script>
  import './index.scss'

  export default {
    props: {
      item: Object
    }
  }
</script>
