<template>
  <div>
    <sub-h5-nominate v-if="$utils.isPhone()" />
    <sub-pc-nominate v-else />
  </div>
</template>
<script>
  import subH5Nominate from '@/views/submodule/h5/record/nominate'
  import subPcNominate from '@/views/submodule/pc/record/nominate'

  export default {
    components: {
      subH5Nominate,
      subPcNominate
    }
  }
</script>
