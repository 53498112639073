<template>
  <div>
    <sub-h5-referral-code v-if="$utils.isPhone()" />
    <sub-pc-referral-code v-else />
  </div>
</template>
<script>
  import subH5ReferralCode from '@/views/submodule/h5/record/referralCode'
  import subPcReferralCode from '@/views/submodule/pc/record/referralCode'

  export default {
    components: {
      subH5ReferralCode,
      subPcReferralCode
    }
  }
</script>
