<template>
  <div class="container main-page pc-record-referral-code-box">
    <sub-head-navs />
    <div class="breadcrumb-box">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
        <el-breadcrumb-item :to="{ name: 'Home', query: { id: uccnInfo.id }, params: {pathMatch}}">招聘首页</el-breadcrumb-item>
        <el-breadcrumb-item>推荐码记录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="referral-code-table-box">
      <el-table
        v-loading="isLoading"
        class="referral-code-table"
        :data="recordList"
        row-key="id"
        style="width: 100%">
        <el-table-column prop="name" label="候选人"></el-table-column>
        <el-table-column prop="code" label="推荐码"></el-table-column>
        <el-table-column prop="content" label="使用情况"></el-table-column>
        <el-table-column prop="createTime" label="操作时间">
          <template slot-scope="scope">{{ scope.row.createTime | DATE('YYYY-MM-DD HH:mm') }}</template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Breadcrumb, BreadcrumbItem, Table, TableColumn, Pagination } from 'element-ui'
  import { local } from '@/utils'
  import { OutRefuseType } from '@/enums/EnumRecommend'
  import subHeadNavs from '@/components/pc/headNavs'

  export default {
    components: {
      [Breadcrumb.name]: Breadcrumb,
      [BreadcrumbItem.name]: BreadcrumbItem,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Pagination.name]: Pagination,
      subHeadNavs
    },
    data() {
      return {
        OutRefuseType,
        isLoading: false,
        recordList: []
      }
    },
    computed: {
      ...mapGetters(['uccnInfo']),
      pathMatch() {
        return local.get('rootPath') || '';
      }
    },
    created() {
      this.queryData();
    },
    methods: {
      queryData() {
        this.isLoading = true;
        this.$http.get('/api/school/ambassador/h5/recomend/code/list.json', {params: {
          websiteId: this.uccnInfo.id
        }}).then(data => {
          if(data && data.length) {
            this.recordList = data;
          }
        }).finally(() => {
          this.isLoading = false;
        });
      }
    }
  }
</script>
