// 内推奖励流程
export const RewardStage = {
  1: '推荐奖励',
  2: '推给用人部门',
  3: '用人部门筛选通过',
  4: '进入面试阶段奖励',
  5: '候选人到面奖励',
  6: '进入offer阶段奖励',
  7: '发放offer阶段奖励',
  8: '入职奖励',
  9: '转正奖励',
  10: '入职后奖励',
  11: '职位级别奖励',
  12: '简历标签奖励'
};

// 淘汰原因
export const OutRefuseType = {
  1: '学历不合适',
  2: '资历不合适',
  3: '薪资不合适',
  4: '没有通过面试',
  5: '有更合适的人选',
  6: '工作地点不合适',
  7: '人选拒绝offer',
  8: '入职时间不合适',
  9: '人选看其他机会',
  10: '其他原因'
};
// 我的推荐的tab
export const RecommendTabs = {
  0: '推荐记录',
  1: '积分记录',
  2: '奖金记录',
}

