<template>
  <div class="record-nominate-item" @click="$router.push({ name: 'jobDetail', params: { id: item.jobId }, query: { websiteId: uccnInfo.id, recommendId: item.recommendId }})">
    <div class="item-record">
      <div class="name-code">
        <span class="name">{{ item.name }}</span>
        <span class="status referral-code">{{ item.code }}</span>
      </div>
      <span class="status" :class="{'eliminate': item.recomResult === 3}">{{ item.resultName }}</span>
    </div>
    <div class="item-record">
      <span class="deliver-job el-sl">投递：{{ item.jobName }}</span>
      <span class="create-time">{{ item.modifyTime | DATE('YYYY-MM-DD') }}</span>
    </div>
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      item: Object
    },
    computed: {
      ...mapGetters({
        uccnInfo: ['uccnInfo']
      })
    }
  }
</script>
